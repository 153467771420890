(function ($) {

    $(function () {

        var currentAjaxDelay = null;
        var currentAjaxRequest = null;
        var previousSearchValue = '';
        var hasResults = false;

        var $searchTextBox = $("#keywords");
        var $searchSuggestions = $("#searchSuggestions");
        var url = $searchTextBox.data("url");

        function doAjaxRequest(keywords, url) {
            if (currentAjaxRequest) currentAjaxRequest.abort();
            currentAjaxRequest = $.get(
                url,
                { keywords: keywords },
                function (htmlStr) {
                    $searchSuggestions.html($(htmlStr).find("#searchResults").html()).show();
                    currentAjaxRequest = null;
                    hasResults = true;
                }
            );
        }

        // when there is activity in the text box and it's not empty, the search box appears
        $searchTextBox.on("propertychange keyup input paste", function (e) {
            // get the search value from the input
            var searchValue = $searchTextBox.val();

            // if the value is empty now, hide the suggestions
            if (searchValue === '') {
                // hide the clear button
                $(this).next(".clearSearch").stop().fadeOut(100);

                $searchSuggestions.hide();
                hasResults = false;
            } else if (searchValue !== previousSearchValue) { // non-empty and changed;
                // show the clear button
                $(this).next(".clearSearch").stop().fadeIn(100);

                // keep track of the previous state
                previousSearchValue = searchValue;

                // cancel the current timeout (if any)
                clearTimeout(currentAjaxDelay);

                // time delay to allow keypress to register
                currentAjaxDelay = setTimeout(function () {
                    doAjaxRequest(searchValue, url);
                }, 400);
            } // otherwise do nothing (the state didn't change)
        });

        // show on click if there's something to show
        $searchTextBox.on("click", function (e) {
            if (hasResults)
                $searchSuggestions.show();
        });

        // clear search functionality
        $(".clearSearch").on("click", function () {
            $searchTextBox.val("");
            $(this).delay(100).fadeOut(100);
            // hide the suggestions
            $searchSuggestions.hide();
            hasResults = false;
        });

        // hide suggestions when we click away
        $(document).on("click", function (evt) {
            if (!$(evt.target).parents().is("#searchBar")) {
                $searchSuggestions.hide();
            }
        });

        // hide suggestions when we tab away
        $(document).on("keyup", function (evt) {
            if (evt.which == 9 && !$(document.activeElement).parents().is("#searchBar")) {
                $searchSuggestions.hide();
            }
        });

    });

})(jQuery);