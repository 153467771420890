(function ($) {

    $(function () {
        showContent = function (show, hide) {
            $(hide).hide();
            $(show).delay(400).show();
        }

        // Randomise hero images for desktop on page load
        // Add images
        var heroClass = ['hero-burgers', 'hero-cleaning', 'hero-croissant', 'hero-gym', 'hero-laptop'];

        // Build the img, then do a bit of maths to randomize load and append to a div

        $('#hero').each(function () {
            $(this).addClass(heroClass[~~(Math.random() * heroClass.length)]);
        });

        // View more categories*
        $("#desktop").on('click', 'a', function (evt) {
            evt.preventDefault();
            evt.stopPropagation();
            if ($("div#all-categories").is(':visible')) {
                $(this).find('span').html('View More');
            }
            else {
                $(this).find('span').html('Close');
            }
            $("div#all-categories").toggle();
            $(this).toggleClass('active');
        });

        // Timeline slider
        $('.tab-link').on("click", function () {
            var tab_id = $(this).attr('data-tab');
            $('.tab-link').removeClass('current');
            $('.tab-content').removeClass('current');
            $(this).addClass('current');
            $("#" + tab_id).addClass('current');
        });

        ssm.addState({
            id: 'medium',
            maxWidth: 750,
            onEnter: function () {
                // Mobile sliders (spotlights and franchise timeline section)
                $(".hot-franchises-tiles").owlCarousel({
                    items: 2,
                    dots: true,
                    lazyLoad: true,
                    slideSpeed: 800,
                    nav: false,
                    responsive: {
                        0: {
                            items: 1
                        },
                        460: {
                            items: 2
                        },
                        750: {
                            items: 3
                        }
                    }
                });
            }
        })

        ssm.addState({
            id: 'small',
            maxWidth: 650,
            onEnter: function () {
                // Mobile sliders for the franchise journey section
                $(".advice-article-owl").owlCarousel({
                    autoWidth: true, autoHeight: true


                });
            }
        }).ready();
    });

})(jQuery);